html,
body,
.App,
#app > div {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
  font-family: "Jost", "calibri", sans-serif;
}

.min100vh {
  min-height: 100vh;
}

.max100vh {
  max-height: 100vh;
}

.inheritMaxHeight {
  max-height: inherit;
  overflow-y: auto;
}

.App-with-footer {
  margin-bottom: -190px;
  padding-bottom: 190px;
}

.no-overflow {
  overflow: hidden;
}

.no-pointer-events {
  pointer-events: none;
}

.beta-pill {
  font-size: 0.7rem !important;
  font-weight: 300;
  margin-top: -10px !important;
  position: absolute;
  top: 18px;
  left: 100;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: "Jost", Arial, Helvetica, sans-serif;
}

.transparent {
  background-color: transparent !important;
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0) !important;
}

.bodyBackground {
  background-color: #f5f5f5;
}

body {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  font-family: "Jost", Helvetica, Arial, sans-serif;
  color: #262626;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  flex-shrink: 0;
  display: flex;
  border-top: 3px solid #5e9fff;
  margin-top: 40px;
}

.force-form {
  position: absolute;
  right: 5px;
  top: 5px;
}

.bigRound {
  border-radius: 25px;
  overflow: hidden;
}

#notificationList .selectedNav {
  border-left: 5px solid #8ac7ff !important;
  background-color: #d9edff;
  border-left-width: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

#notificationList .list-group-item.active {
  z-index: 2;
  color: #000000;
  background-color: #ffffff;
  border-color: #ddd;
  border-left: 5px solid #2997ff !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

#notificationList .selectedNav:hover {
  background-color: #c6e4ff;
}

#notificationList .list-group-item {
  border-left: 5px solid #ffffff;
  border-left-width: 5px !important;
}

#notificationList .list-group-item:hover {
  border-left: 5px solid #f5f5f5;
}

.nav-big {
  padding-top: 40px;
  padding-bottom: 40px;
  transition: padding 0.2s, background-color 0.2s;
}

.nav-small {
  background-color: #2998fff3;
  padding: 20px 40px;
}

#bellButton #bell {
  transition: transform 0.1s ease;
}

.animated {
  transition: all 0.1s ease;
}

.hoverableThing {
  transition: all 0.3s ease;
}

.borderable {
  border: 1px solid transparent;
  transition: all 0.2s ease;
  box-shadow: 5px 5px 20px #dddddd;
  border-radius: 12px;
}

.borderable .card-img-top {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.no-underline {
  text-decoration: none !important;
}

.borderable:hover {
  box-shadow: 5px 5px 25px #cccccc;
}

.aquaBg,
.aquaBgHover:hover {
  background: linear-gradient(to right, #00cdac, #02aab0);
}

.redBg,
.redBgHover:hover {
  background: linear-gradient(to right, red, purple);
}

.hoverableThing:hover {
  transform: scale(1.01) translateY(-3px);
  box-shadow: 5px 5px 10px rgba(70, 70, 70, 0.8);
}

#bellButton:hover #bell {
  /* transform: rotate(20deg) scale(1.1); */
  animation-name: bellRing;
  animation-duration: 0.3s;
}

@keyframes bellRing {
  0% {
    transform: rotate(0deg) scale(1.04);
  }
  5% {
    transform: rotate(25deg) scale(1.04);
  }
  30% {
    transform: rotate(-10deg) scale(1.04);
  }

  100% {
    transform: rotate(5deg) scale(1.04);
  }
}

.bg-none {
  background: none !important;
}

.modal-content {
  border: 0px;
}

.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.img-thumbnail {
  padding: 0 !important;
  border: 0px;
}

.circular {
  border-radius: 100%;
}

#navbar {
  border-top: 2px solid #2997ff;
  padding-top: 2px;
}

.fileNamePopup {
  position: absolute;
  bottom: -10px;
  left: 0;
  opacity: 0;
  box-shadow: 0px 5px 5px #dddddd;
  max-width: 100%;
  pointer-events: none;
  overflow-wrap: break-word;

  background-color: rgba(245, 245, 245, 1);
  padding: 0px 4px;
  align-items: center;
  transition: all 0.1s ease;
}

.col-thumb:hover .fileNamePopup {
  bottom: 0;
  left: 0;
  opacity: 1 !important;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.notification-container {
  text-align: left;
  font-size: 0.8rem;
  margin-top: 70px;
}

.react-photo-gallery--gallery img {
  max-width: 140px;
  max-height: 140px;
}

/* .footer {
  position: relative;
  bottom: 0;
} */

.hidden {
  display: none !important;
}

.h40onLarge {
  height: 40px;
}

.op-hover:hover {
  opacity: 0.5;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.dashed {
  border: 2px dashed #999999;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.no-events {
  pointer-events: none;
}

.text-lgray {
  color: #999999;
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.glow:hover {
  -moz-box-shadow: 0 0 5px 5px #dedede;
  -webkit-box-shadow: 0 0 5px 5px #dedede;
  box-shadow: 0 0 5px 5px #dedede;
}

.glow-yellow:hover {
  -moz-box-shadow: 0 0 15px 5px rgb(255, 222, 179);
  -webkit-box-shadow: 0 0 15px 5px rgb(255, 222, 179);
  box-shadow: 0 0 15px 5px rgb(255, 222, 179);
}

.glow-yellow {
  transition: transform 0.2s;
}

.glow-yellow:focus {
  -moz-box-shadow: 0 0 15px 5px rgb(255, 222, 179);
  -webkit-box-shadow: 0 0 15px 5px rgb(255, 222, 179);
  box-shadow: 0 0 15px 5px rgb(255, 222, 179);
  outline: 0px solid blue;
  transform: scale(1.05);
}

.pointer {
  cursor: pointer;
}

.f-20 {
  font-size: 32px;
}

.endTour {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 100000;
  pointer-events: none;
}

.endTourButton {
  border-radius: 25px;
  pointer-events: all;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.sinked {
  background-color: #f3f3f3;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.hover-grey:hover {
  background-color: #dedede;
}

body::-webkit-scrollbar {
  display: none;
}

.fancyScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.fancyScroll::-webkit-scrollbar {
  width: 7px;
  background-color: none;
}

.fancyScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgray;
}

.content-wrapper {
  flex: 1;
  position: relative;
}

.fill {
  height: 100%;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.box-100 {
  min-width: 100px;
  min-height: 100px;
}

.max600 {
  max-width: 600px;
}

.max500 {
  max-width: 500px;
}

.max300 {
  max-width: 300px;
}

.max-100 {
  max-width: 100%;
}

.max800 {
  max-width: 800px;
}

.small-font {
  font-size: 0.9rem;
}

.fnt-500 {
  font-weight: 500;
}

.fnt-700 {
  font-weight: 700;
}

.fnt-900 {
  font-weight: 900;
}

.fnt-100 {
  font-weight: 100;
}

ul.nav li.active a {
  color: white !important;
}

.rounded-25 {
  border-radius: 25px;
}

a {
  color: #999999;
}

.starter-subscription-border-wrap {
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, #80b3ff, #5e9fff);
  padding: 3px;
}

.nav-pills .nav-link.active {
  background: linear-gradient(to left, #66b8ff, #237cff);
  font-weight: 500;
}

.starter-subscription {
  color: #282c34;
  padding: 2rem 1rem;
  background: rgba(255, 255, 255, 0.9);
}

.basic-subscription-border-wrap {
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, #00cdac, #02aab0);
  padding: 3px;
}

.basic-subscription {
  color: #282c34;
  padding: 2rem 1rem;
  background: rgba(255, 255, 255, 0.9);
}

.progress-custom {
  color: white;
  height: 10px;
}

.plus-subscription-border-wrap {
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, #fad400, #ff9966);
  padding: 3px;
}

.bg-black {
  background-color: black;
}

.plus-subscription {
  color: #282c34;
  padding: 2rem 1rem;
  background: rgba(255, 255, 255, 0.9);
}

.premium-subscription-border-wrap {
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, red, purple);
  padding: 3px;
}

.premium-subscription {
  color: #282c34;
  padding: 2rem 1rem;
  background: rgba(255, 255, 255, 0.9);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 35%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#photosetDetailsButtons .month-picker > .rmp-container .rmp-popup {
  right: -40px;
  top: 25px;
  margin-top: 35px !important;
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.materialInput {
  background-color: #eeeeee;
  border: 1px solid #eeeeee !important;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.futura {
  font-family: "Futura", Arial, sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.pill-button {
  border-radius: 30px;
}

.embossed {
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
  border-radius: 50%;
  border: 1px solid #ececec;
  outline: none !important;
}

.part-1 {
  background-size: 350px;
  background-position: 80% 50%;
  min-height: 800px;
  padding-top: 200px;
  z-index: 1;
  position: relative;
  background-color: #2997ff !important;
}

.bg-part-1 {
  background-color: rgba(255, 255, 255, 0.8);
}

ul.footer_list {
  list-style: none;
  padding-left: 0px;
}

ul.footer_list li {
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.6;
  font-weight: 500;
  font-size: 14px;
}

ul.footer_list li:hover {
  cursor: pointer;
  color: #2997ff;
}

/* .part-1::after {
  content: "";
  width: 100%;
  height: 620px;
  background: #fff;
  z-index: 1;
  bottom: -610px;
  background-color: #fff;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-4deg);
  -ms-transform: skewY(-4deg);
  transform: skewY(-4deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

.part-2 {
  z-index: 2;
  position: relative;
  width: 100%;
  background-color: white;
}

/* .part-2::before {
  /* content: "";
  border-radius: 0 0 50% 50%/ 0 0 100% 100%;
  transform: scaleX(2.5);
  position: absolute;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: white;
  z-index: 0; 
} */

.borderWhite {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 25px 50px;
  background-color: rgba(255, 255, 255, 0.973);
}

.funkyCorners {
  border-radius: 25px 50px;
}

/* .part-2::after {
  content: "";
  width: 100%;
  height: 150px;
  background: #fff;
  z-index: 0;
  bottom: -150px;
  background-color: #fff;
  left: 0;
  position: absolute;
  -webkit-transform: skewY(-4deg);
  -ms-transform: skewY(-4deg);
  transform: skewY(-4deg);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
} */

.max-400 {
  max-width: 400px;
}

.notfound .notfound-404 h3 {
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.input-group input:focus {
  outline: none;
}

.redHover:hover {
  background-color: rgb(255, 204, 204);
  transition: all 0.2s ease;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;

  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

.prevButton {
  position: absolute;
  top: 0%;
  height: 100%;
  left: 0px;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  width: 80px;
}

.nextButton {
  position: absolute;
  top: 0%;
  height: 100%;
  right: 0;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.1s ease;
  width: 80px;
}

.prevButton svg,
.nextButton svg {
  position: absolute;
  top: 50%;
}

.prevButton svg {
  left: 27%;
}

.nextButton svg {
  right: 27%;
}

.prevButton:hover,
.nextButton:hover {
  transform: scale(1.01);
  color: #282c34;
  background-color: #ffffff88;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px;
  }
  .notfound .notfound-404 h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px;
  }
  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }
  .notfound h2 {
    font-size: 16px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199) {
  .h-97-lg {
    height: 702px;
  }
}

.part-1 {
  background-position: 80% 50% !important;
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .h-97-xl {
    height: 702px;
  }
}

/* super small screen */
@media only screen and (max-width: 496px) {
  .h40onLarge {
    height: 120px !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 767px) {
  .b-xs-none {
    border: none !important;
  }

  .part-1 {
    background-position: 50% 130% !important;
  }

  .h40onLarge {
    height: 80px;
  }
  .part-1 {
    background-position: 50% 20% !important;
    background-size: 250px !important;
    padding-top: 300px !important;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .b-sm-none {
    border: none !important;
  }

  .part-1 {
    background-position: 80% 90% !important;
  }

  .h40onLarge {
    height: 60px;
  }

  .h40onLarge {
    height: auto;
  }
}
